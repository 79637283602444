import React, { useState, Fragment, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import clsx from 'clsx';

import AuthActions from 'redux/actions/auth';

// Assets
import logoimage from '../assets/img/myschoolasia-logo.png'
import academicSetup from '../images/icon/academic-setup.svg'
import account from '../images/icon/account.svg'
import announcements from '../images/icon/announcements.svg'
import attendance from '../images/icon/attendance.svg'
import calendar from '../images/icon/calendar.svg'
import discipline from '../images/icon/discipline.svg'
import fees from '../images/icon/fees.svg'
import messages from '../images/icon/messages.svg'
import overview from '../images/icon/overview.svg'
import results from '../images/icon/results.svg'
import systemAdmin from '../images/icon/system-admin.svg'
import timetable from '../images/icon/timetable.svg'

// Components
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import SchoolIcon from '@material-ui/icons/School';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import PaymentIcon from '@material-ui/icons/Payment';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import EventNoteIcon from '@material-ui/icons/EventNote';
import MessageIcon from '@material-ui/icons/Message';
import TimelineIcon from '@material-ui/icons/Timeline';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import DraftsIcon from '@material-ui/icons/Drafts';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import ListIcon from '@material-ui/icons/List';
import Avatar from '@material-ui/core/Avatar';
import TuneIcon from '@material-ui/icons/Tune';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

import Session from 'services/local/session';
import useSession from 'hooks/use-session';
import { uniq } from 'lodash';

const mapStateToProps = (state) => ({
  status: state.auth?.status,
  resErr: state.auth?.error
})

const mapDispatchToProps = (dispatch) => {
  const authActions = bindActionCreators(AuthActions, dispatch);

  return {
    $onLogout: () => {
      authActions.logout();
    },
    $onReset: () => {
      authActions.resetStatus();
    },
    onFetchRefreshToken$: () => {
      authActions.refreshToken();
    }
  };
}

const Layout = ({ status, resErr, $onLogout, $onReset, onFetchRefreshToken$, content, history }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [nestedOpen, setNestedOpen] = useState(false);
  const [academicSetupNested, setAcademicSetupOpen] = useState(false);
  const [systemAdminSetupNested, setSystemAdminSetupOpen] = useState(false);
  const [drawerIcon, setDrawerIcon] = useState(<DoubleArrowIcon />);
  const [menuPosition, setMenuPosition] = useState("30%")
  const sessionObj = useSession();
  const uniquePermissions = uniq(sessionObj.permissions);
  // useEffect(() => {
  //   if (status.renew !== 'success') {
  //     const interval = setInterval(() => { tokenLifetime() }, (5 * 60 * 1000)); // check every 5 minutes
  //     return () => clearInterval(interval);
  //   }
  // }, [status]);

  const canViewAnnouncement = (uniquePermissions || []).includes('v_ann_web');
  const canViewSysAdmin = (uniquePermissions || []).includes('v_sys_ad');
  const canViewRoles = (uniquePermissions || []).includes('v_sys_roles');
  const canViewGroups = (uniquePermissions || []).includes('v_sys_group');
  const canViewUserPermissions = (uniquePermissions || []).includes('v_sys_all_users');


  useEffect(() => {
    if (status.renew == 'failed') {
      $onReset();
      history.push('/login');
      setTimeout(() => {
        Session.removeSession();
        sessionStorage.clear();
        localStorage.clear();
      }, 1000);
    }
  }, [status]);

  useEffect(() => {
    const interval = setInterval(() => { tokenLifetime() }, (5 * 60 * 1000)); // check every 5 minutes
    return () => clearInterval(interval);
  }, []);

  const tokenLifetime = () => {
    const lifetime = Session.check();
    if (lifetime) {
      const minutes = Math.floor(lifetime / (60 * 1000));
      // refresh token if less than 5 minutes
      if (minutes <= 5) {
        onFetchRefreshToken$();
      }
    }
  }


  useEffect(() => {
    if (status.logout === 'success') {
      $onReset();
      history.push('/login');
      setTimeout(() => {
        Session.removeSession();
        sessionStorage.clear();
        localStorage.clear();
      }, 1000);
    }
  }, [status.logout]);

  const [selectedIndex, setSelectedIndex] = useState();

  const handleListItemClick = (event, index) => {

    setSelectedIndex(index);

    switch (index) {
      case 0: {
        history.push('/overview');
        break;
      }

      case 1: {
        history.push('/attendance');
        break;
      }

      case 2: {
        history.push('/announcement');
        break;
      }

      case 3: {
        history.push('/fees');
        break;
      }

      case 4: {
        history.push('/discipline');
        break;
      }

      case 5: {
        history.push('/timetable');
        break;
      }

      case 6: {
        history.push('/messages');
        break;
      }

      case 7: {
        history.push('/results');
        break;
      }

      case 8: {
        history.push('/calendar');
        break;
      }

      case 9: {
        break;
      }

      case 10: {
        history.push('/account/students');
        break;
      }

      case 11: {
        history.push('/account/teachers');
        break;
      }

      case 12: {
        history.push('/account/drafts');
        break;
      }

      case 13: {
        //history.push('/masterlist');
        break;
      }

      case 14: {
        history.push('/academic_calendar');
        break;
      }

      case 15: {
        history.push('/masterlist');
        break;
      }

      case 16: {
        //history.push('/masterlist');
        break;
      }

      case 17: {
        break;
      }

      case 18: {
        history.push('/systemadmin/roles')
        break;
      }

      case 19: {
        history.push('/systemadmin/groups')
        break;
      }

      case 20: {
        history.push('/systemadmin/users')
        break;
      }


      default: {
        history.push('/');
      }
    }
  };

  const handleDrawer = () => {
    if (open === true) {
      setDrawerIcon(<DoubleArrowIcon />);
      setOpen(false);
      setMenuPosition("30%");
      setNestedOpen(false);
      setAcademicSetupOpen(false);
      setSystemAdminSetupOpen(false);
    }
    else {
      setOpen(true);
      setDrawerIcon(<ChevronLeftIcon />);
      setMenuPosition("5%");

    }
  }

  const onLogout = () => {

    history.push('/login');
    // $onReset();
    // $onLogout();
      Session.removeSession();
      sessionStorage.clear();
      localStorage.clear();
  }

  const handleClick = (event, index) => {
    switch (index) {
      case 9:
        setNestedOpen(!nestedOpen);
        handleListItemClick(event, index);
        setOpen(true);
        setDrawerIcon(<ChevronLeftIcon />);
        break;
      case 13:
        setAcademicSetupOpen(!academicSetupNested);
        handleListItemClick(event, index);
        setOpen(true);
        setDrawerIcon(<ChevronLeftIcon />);
        break;
      case 17:
        setSystemAdminSetupOpen(!systemAdminSetupNested);
        handleListItemClick(event, index);
        setOpen(true);
        setDrawerIcon(<ChevronLeftIcon />);
        break;
      default:
        break;
    }

  };

  return (
    <Fragment>
      <CssBaseline />

      <Drawer
        position="flex"
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar} style={{ marginTop: '80px' }} >
          <IconButton onClick={handleDrawer}>
            {/* {open && (<Typography style={{ fontSize: 24, color: 'black', marginRight: '15%', fontWeight: 'bold' }}>My School Asia</Typography>)} */}
            {drawerIcon}
          </IconButton>
        </div>

        {/* {open &&(<div style = {{marginLeft: '20%'}}>
                  <Avatar src = {process.env.PUBLIC_URL + "/Ellipse40.png"} className = {classes.largeAvatar}/>
                  <Typography style = {{fontSize: 18, marginTop: '1%'}}>Sarah Mc Coy</Typography>
                  <Typography style = {{fontSize: 14}}>Admin</Typography>
                </div>)} */}

        <List
          style={{ marginLeft: '10%' }}

        >
          {/* <ListItem button key='Overview' selected={selectedIndex === 0} onClick={(event) => { handleListItemClick(event, 0) }} classes={{ root: classes.root, selected: classes.selected }} >

            <img src={overview} style={{ maxWidth: '20px' }} />
            {open === true && <ListItemText primary='Overview' style={{ marginLeft: '10px' }} />}
          </ListItem> */}

          <ListItem button key='Attendance' selected={selectedIndex === 1} onClick={(event) => { handleListItemClick(event, 1) }} classes={{ root: classes.root, selected: classes.selected }}>

            <img src={attendance} style={{ maxWidth: '20px' }} />
            {open === true && <ListItemText primary='Attendance' style={{ marginLeft: '10px' }} />}
          </ListItem>

          {canViewAnnouncement ? <ListItem button key='Announcement' selected={selectedIndex === 2} onClick={(event) => { handleListItemClick(event, 2) }} classes={{ root: classes.root, selected: classes.selected }}>
            <img src={announcements} style={{ maxWidth: '20px' }} />
            {open === true && <ListItemText primary='Announcement' style={{ marginLeft: '14px' }} />}
          </ListItem> : ''}

          {/* <ListItem button key='Fees' selected={selectedIndex === 3} onClick={(event) => { handleListItemClick(event, 3) }} classes={{ root: classes.root, selected: classes.selected }}>
            <img src={fees} style={{ maxWidth: '20px' }} />
            {open === true && <ListItemText primary='Fees' style={{ marginLeft: '10px' }} />}
          </ListItem>

          <ListItem button key='Discipline' selected={selectedIndex === 4} onClick={(event) => { handleListItemClick(event, 4) }} classes={{ root: classes.root, selected: classes.selected }}>
            <img src={discipline} style={{ maxWidth: '20px' }} />
            {open === true && <ListItemText primary='Discipline' style={{ marginLeft: '10px' }} />}
          </ListItem> */}

          <ListItem button key='Timetable' selected={selectedIndex === 5} onClick={(event) => { handleListItemClick(event, 5) }} classes={{ root: classes.root, selected: classes.selected }}>
            <img src={timetable} style={{ maxWidth: '20px' }} />
            {open === true && <ListItemText primary='Timetable' style={{ marginLeft: '10px' }} />}
          </ListItem>

          {/* <ListItem button key='Messages' selected={selectedIndex === 6} onClick={(event) => { handleListItemClick(event, 6) }} classes={{ root: classes.root, selected: classes.selected }}>
            <img src={messages} style={{ maxWidth: '20px' }} />
            {open === true && <ListItemText primary='Messages' style={{ marginLeft: '10px' }} />}
          </ListItem>

          <ListItem button key='Results' selected={selectedIndex === 7} onClick={(event) => { handleListItemClick(event, 7) }} classes={{ root: classes.root, selected: classes.selected }}>
            <img src={results} style={{ maxWidth: '20px' }} />
            {open === true && <ListItemText primary='Results' style={{ marginLeft: '10px' }} />}
          </ListItem>

          <ListItem button key='Calendar' selected={selectedIndex === 8} onClick={(event) => { handleListItemClick(event, 8) }} classes={{ root: classes.root, selected: classes.selected }}>
            <img src={calendar} style={{ maxWidth: '20px' }} />
            {open === true && <ListItemText primary='Calendar' style={{ marginLeft: '10px' }} />}
          </ListItem> */}

          <ListItem button onClick={(event) => { handleClick(event, 9) }} key='Account' selected={selectedIndex === 9} classes={{ root: classes.root, selected: classes.selected }}>
            <img src={account} />
            {open === true && <ListItemText primary="Account" style={{ marginLeft: '10px' }} />}
            {nestedOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={nestedOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} key='Students' selected={selectedIndex === 10} onClick={(event) => { handleListItemClick(event, 10) }} classes={{ root: classes.root, selected: classes.selected }}>
                {/* <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon> */}
                <ListItemText primary="Students" style={{ marginLeft: '20px' }} />
              </ListItem>
              <ListItem button className={classes.nested} key='Teachers' selected={selectedIndex === 11} onClick={(event) => { handleListItemClick(event, 11) }} classes={{ root: classes.root, selected: classes.selected }}>
                {/* <ListItemIcon>
                  <SupervisorAccountIcon />
                </ListItemIcon> */}
                <ListItemText primary="Teachers" style={{ marginLeft: '20px' }} />
              </ListItem>
              {/* <ListItem button className={classes.nested} key = 'Drafts' selected = {selectedIndex === 12} onClick = {(event) => {handleListItemClick(event, 12)}} classes={{ root: classes.root, selected: classes.selected }}>
                        <ListItemIcon>
                          <DraftsIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Drafts"/>
                      </ListItem> */}
            </List>
          </Collapse>

          <ListItem button onClick={(event) => { handleClick(event, 13) }} key='Academic Setup' selected={selectedIndex === 13} classes={{ root: classes.root, selected: classes.selected }}>
            {/* <ListItemIcon><TuneIcon /></ListItemIcon> */}
            <img src={academicSetup} style={{ maxWidth: '20px' }} />
            {open && <ListItemText primary="Academic Setup" style={{ marginLeft: '10px' }} />}
            {academicSetupNested ? <ExpandLess /> : <ExpandMore />}

          </ListItem>

          <Collapse in={academicSetupNested} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} key='Academic Calendar' selected={selectedIndex === 14} onClick={(event) => { handleListItemClick(event, 14) }} classes={{ root: classes.root, selected: classes.selected }}>
                {/* <ListItemIcon><EventNoteIcon /></ListItemIcon> */}
                <ListItemText primary='Academic Calendar' style={{ marginLeft: '20px' }} />
              </ListItem>
              <ListItem button className={classes.nested} key='Master List' selected={selectedIndex === 15} onClick={(event) => { handleListItemClick(event, 15) }} classes={{ root: classes.root, selected: classes.selected }}>
                {/* <ListItemIcon>
                  <ListIcon />
                </ListItemIcon> */}
                <ListItemText primary="Master List" style={{ marginLeft: '20px' }} />
              </ListItem>
              {/* <ListItem button className={classes.nested} key='Roles' selected={selectedIndex === 16} onClick={(event) => { handleListItemClick(event, 16) }} classes={{ root: classes.root, selected: classes.selected }}>
                <ListItemIcon>
                  <PermIdentityIcon />
                </ListItemIcon>
                <ListItemText primary="Roles" />
              </ListItem> */}
            </List>
          </Collapse>

          {canViewSysAdmin && <ListItem button onClick={(event) => { handleClick(event, 17) }} key='System Admin' selected={selectedIndex === 17} classes={{ root: classes.root, selected: classes.selected }}>
            {/* <ListItemIcon><TuneIcon /></ListItemIcon> */}
            <img src={systemAdmin} style={{ maxWidth: '20px' }} />
            {open && <ListItemText primary="System Admin" style={{ marginLeft: '10px' }} />}
            {systemAdminSetupNested ? <ExpandLess /> : <ExpandMore />}
          </ListItem>}
          <Collapse in={systemAdminSetupNested} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {canViewRoles && <ListItem button className={classes.nested} key='Roles' selected={selectedIndex === 18} onClick={(event) => { handleListItemClick(event, 18) }} classes={{ root: classes.root, selected: classes.selected }}>
                {/* <ListItemIcon><EventNoteIcon /></ListItemIcon> */}
                <ListItemText primary='Roles' style={{ marginLeft: '20px' }} />
              </ListItem>}
              {canViewGroups && <ListItem button className={classes.nested} key='Groups' selected={selectedIndex === 19} onClick={(event) => { handleListItemClick(event, 19) }} classes={{ root: classes.root, selected: classes.selected }}>
                {/* <ListItemIcon>
                  <ListIcon />
                </ListItemIcon> */}
                <ListItemText primary="Groups" style={{ marginLeft: '20px' }} />
              </ListItem>}
              {canViewUserPermissions && <ListItem button className={classes.nested} key='Users' selected={selectedIndex === 20} onClick={(event) => { handleListItemClick(event, 20) }} classes={{ root: classes.root, selected: classes.selected }}>
                {/* <ListItemIcon>
                  <PermIdentityIcon />
                </ListItemIcon> */}
                <ListItemText primary="Users" style={{ marginLeft: '20px' }} />
              </ListItem>}
            </List>
          </Collapse>

        </List>

        {open && (<List style={{ marginTop: '15%', marginLeft: '10%' }}>

          <ListItem button key='Help' onClick={(event) => { window.open('https://myschool.asia/contact-us/', '_target') }}>
            <ListItemText primary='Help' />
          </ListItem>
          <ListItem button key='About' onClick={(event) => { window.open('https://myschool.asia/', '_target') }}>
            <ListItemText primary='About' />
          </ListItem>
          <ListItem button key='Logout'
            //   onClick={$onLogout}
            onClick={(event) => { onLogout() }}
          >
            <ListItemText primary='Logout' />
          </ListItem>
        </List>)}
      </Drawer>

      <AppBar
        position="fixed"
        className={clsx(classes.appBar)}
      >

        <Toolbar>
          <img src={logoimage} width={70} height={70} />
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', marginLeft: '40px', minHeight: '80px' }}>
            <Typography variant="h5" noWrap style={{ color: 'black', fontStyle: 'italic', fontSize: '19px' }}>
              {moment().format('dddd')} | {moment().format('Do MMM YYYY')}
            </Typography>

            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '50%', alignItems: 'center' }}>
              <IconButton>
                <NotificationsNoneOutlinedIcon style={{ fontSize: 30 }} />
              </IconButton>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography style={{ color: 'black', fontSize: 18, fontFamily: "Poppins" }}>{sessionObj?.profile?.firstName + ' ' + sessionObj?.profile?.lastName}</Typography>
                <Typography style={{ color: 'black', fontSize: 14, fontFamily: "Poppins", textTransform: "capitalize" }}>{sessionObj?.profile?.type}</Typography>
              </div>
              {/* <Avatar src={process.env.PUBLIC_URL + "/Ellipse40.png"} className={classes.largeAvatar} /> */}

              {sessionObj?.profile?.profile_picture == false ?
                <Avatar style={{ background: 'linear-gradient(213.06deg, #FFC000 4.6%, #FF8A00 81.54%)' }}>{sessionObj?.profile?.firstName.charAt(0)}</Avatar> :
                <Avatar src={sessionObj?.profile?.profile_picture} className={classes.largeAvatar} />}

              {/* <ListItem>
                                <ListItemAvatar>
                                    {params.row.profile_picture ? <Avatar>
                                        <img src={params.row.profile_picture} className="MuiAvatar-img" alt="pic" style={{ width: '50px', height: '50px' }} />
                                    </Avatar> : <Avatar style={{ background: 'linear-gradient(213.06deg, #FFC000 4.6%, #FF8A00 81.54%)' }}>{params.value.charAt(0)}</Avatar>}

                                </ListItemAvatar>
                                <ListItemText className={cls.name} primary={params.value} secondary="" onClick={() => onMenuClick({ type: 'EDIT', data: params.row })} />
                            </ListItem> */}
            </div>

          </div>

        </Toolbar>

      </AppBar>

    </Fragment >
  )
}

const drawerWidth = 275;

const useStyles = makeStyles((theme) => ({
  largeAvatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    margin: '10px',
  },

  appBar: {
    zIndex: theme.zIndex.drawer,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: 'white',
    boxShadow: 'none',
    // height: '80px'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: '#FDB714',
    border: 0,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: '#FDB714',
  },
  drawerClose: {
    background: '#FDB714',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },

  nested: {
    paddingLeft: theme.spacing(4),
  },

  // active: {
  //   borderBottomLeftRadius: 20,
  // },

  root: {
    '&$selected': {
      backgroundColor: 'white',
      width: '100%',
      '&:hover': {
        borderBottomLeftRadius: 10,
        borderTopLeftRadius: 10,
        backgroundColor: 'white'
      }
    },
  },
  selected: { borderBottomLeftRadius: 10, borderTopLeftRadius: 10 },
}));


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));
